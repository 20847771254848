




























































































































































































































































































































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import { getComponent } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import DataLoading from '@/lib/mixins/dataLoading';
import TasqMixin from '@/lib/mixins/TasqMixin';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import Multiselect from 'vue-multiselect';
import {
  ALL_TASQS_LIST_ITEM, 
  SHOW_ALERT,
  TASQ_OFF_TARGET_TYPE,
} from '@/lib/constants';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import setpointV2Module from '@/store/modules/setpointV2Module';
import workflowModule from '@/store/modules/workflowModule';
import isOnline from 'is-online';
import proceduresModule from '@/store/modules/proceduresModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqSignal: () => getComponent('tasqs/TasqSignal'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    TasqFeedback: () => getComponent('tasqs/TasqFeedbackV3'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    TasqItem: () => getComponent('tasqs/TasqItem'),
    Multiselect,
  },
})
export default class TasqsMobile extends mixins(DataLoading, TasqMixin) {
  tasqOptionsOpen = false;

  chartsLoading = false;

  signalChartTime = 14;

  isOnlineApp = true;

  subPredictionList: any = [];

  multiTasqType = '';

  activeTasqTab = 'Signals';

  loadingTasqs = false;

  showComment = false;

  showActionDialog: boolean = false;


  action: any = {
    type: '',
    title: '',
    confirmText: '',
    reason: [],
    person: '',
    comment: '',
    date: '',
    well: '',
    jobType: '',
    frequency: '',
    delayedDays: 0,
  };

  chartTimes: any[] = this.$getConst('CHART_TIMES');



	get stepper() {
		return tasqFeedbackModule.stepper
	}


  get isBatchResponding() {
	  return tasqsListModule.isBatchResponding
  }

  batchRespond() {
	  if (this.respondEnabled) {
		  tasqFeedbackModule.resetAllData()
		  tasqsListModule.setIsBatchResponding(true)
		  this.activeTasqTab = "Feedback"
	  }
	  
  }


  get respondEnabled() {
	  return tasqsListModule.batchResponseEnabled
  }


  get selectedEditItemsCount() {
	  return tasqsListModule.checkedTasqs.length
  }


  get getBatchEditResponseDisabledMsg() {
	  return tasqsListModule.batchResponseDisabledMsg
  }


  getWells() {
    const responseData: any[] = [];
	  if (this.activeTasq != null) {
		  // @ts-ignore
		  for (let r = 0; r < this.activeTasq.wells.length; r++) {
			  // @ts-ignore
        //   if (this.activeTasq.wells[r].predictionType != "Producing") {

				  // @ts-ignore
				  responseData.push(this.activeTasq.wells[r]);
        //   }
		  }
		  return responseData;
	  }
	  return responseData;
    //   if (this.showAllWells) {
    // 	  // @ts-ignore
    // 	  return this.tasq.wells
    //   } else {

    //   }
  }

  get isEditing() {
	  return tasqsListModule.isEditing
  }

getPredictionList() {
    console.log(this.activeTasq?.predictionsList)
    if(this.activeTasq && this.activeTasq.predictionsList && Object.keys(this.activeTasq.predictionsList)) {
      let found = false;
        this.subPredictionList = Object.keys(this.activeTasq.predictionsList).map(data => {
        const object:any = this.activeTasq?.predictionsList[data]
        if(data === this.activeTasq?.overriddenName){
          found = true;
        }
         if(data === 'Off-Target RT'){
          data = 'REAL-Time'
        }
		data = this.getOverriddenTasqName(data);
        return {name: data, predictionId: object.PredictionID, color: this.bgColorClass(data) }
      })
      if(!found) {
        this.subPredictionList.push({ name: this.activeTasq.engineerType, predictionId: this.activeTasq.id, color: this.bgColorClass(this.activeTasq.overriddenName) });
      }

    }else {
        this.subPredictionList = [];
    }
  }

  resetTasq() {
	tasqFeedbackModule.setSystemOptionsAvailable([])
	tasqFeedbackModule.setSymptomOptionsAvailable([])
	tasqFeedbackModule.setCauseOptionsAvailable([])
	tasqFeedbackModule.setActionOptionsAvailable([])
  }


  editTasqs() {
	  if (tasqsListModule.isEditing) {
		  this.closeEditToolbar()
	  }
	  else {
		  tasqsListModule.setActiveTasq('');
		  tasqsListModule.setIsEditing(true)
	  }
  }

  closeEditToolbar() {
	  tasqsListModule.setIsEditing(false)
	  this.activeTasqTab = 'Signals'
	  tasqsListModule.setIsBatchResponding(false)
    tasqsListModule.setIsBatchReassign(false)
	  tasqsListModule.resetChekedTasqs()
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setShowTasqDetailsMobile(false);
    assetsModule.setActiveProducingTasq('');
    
    this.$router.push({ name: 'Tasqs' });

  }

  get tasqListLevel() {
	  if (this.activeTasq == null) {
		  return 'WELL';
	  }
	  return this.activeTasq?.level;
  }

   get tasqModuleListLevel() {
	  return tasqsListModule.tasqListLevel;
  }

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentWellType(): any {
	  return this.$route.query.type;
  }

  get signalDescriptions(): any {
	  return tasqSignalsModule.signalDescriptions;
  }

  get currentSignals(): any {
    if (this.chartsLoading) {
      return [];
    }
    const results: any[] = [];
    for (let x = 0; x < this.signalDescriptions.length; x++) {
      for (let y = 0; y < tasqSignalsModule.currentSignals.length; y++) {
        if (tasqSignalsModule.currentSignals[y].name == this.signalDescriptions[x]) {
          results.push(tasqSignalsModule.currentSignals[y]);
        }
      }
    }
    // signalDescriptions
    return results;
  }

  get activeBatchFeedbackTasq() {
	  if (tasqsListModule.checkedTasqs.length > 0) {
		  return tasqsListModule.tasqById(tasqsListModule.checkedTasqs[0])
	  }
	  return null
  }

  get activeTasq() {

     if (this.isEditing || tasqsListModule.checkedTasqs.length ) {
       if(!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
	  }

    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      return tasqsListModule.activeTasq;
    }
    // @ts-ignore
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level == 'PAD') {
      return tasqsListModule.activeTasq;
    }
    if (tasqsListModule.activeTasq) {
      return tasqsListModule.activeTasq;
    }
    return assetsModule.activeTasq;
  }

  get showTasqDetailsMobile() {
    return tasqsListModule.showTasqDetailsMobile;
  }


  get reassignUsers() {
    return accountModule.reassignmentList.map((u) => ({
      text: u.name,
      value: u.email,
    }));
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  setActiveTasqDetailTab(tabName) {
    this.activeTasqTab = tabName;
  }

  clearSelectedTasq() {
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setShowTasqDetailsMobile(false);
    assetsModule.setActiveProducingTasq('');
	if (this.isBatchResponding) {
		this.closeEditToolbar()
	}
	
    this.$router.push({ name: 'Tasqs' });
  }

   async updateTasqPrediction(data){
    // console.log(data)
    await tasqsListModule.getTasq({
    PredictionID: data.predictionId,
    });
    this.multiTasqType = data.name
    workflowModule.getJobResponse(data?.predictionId);
  }

  async created() {
    this.isOnlineApp = await isOnline();
    this.loadingTasqs = true;
    // tasqsListModule.setIsLoadingSearchTasqs(true)
    tasqsListModule.resetPagination();
    tasqsListModule.setActivePage('Tasq');
    // await tasqsListModule.getWaitList()
    // await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);

    const promises: any[] = [];
    promises.push(tasqsListModule.getWaitList());
    promises.push(tasqsListModule.getTasqsByPagination(ALL_TASQS_LIST_ITEM));

    Promise.all(promises).then(() => {
      this.loadingTasqs = false;
      this.stopDataLoading();

      this.fetchTasqAsync();
    }, (err) => {
      // error occurred

    });
  }

  async fetchTasqAsync() {
    assetsModule.getEnabledWells();
    if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {
      await this.fetchTasq();
    }
  }

  beforeDestroy() {
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setActivePage('');
  }

  prepareAction(action) {
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      jobType: '',
      frequency: '',
      delayedDays: 0,
    };
    switch (action) {
      case ('reject'):
        this.action.type = action;
        this.action.title = `Are you sure you want to reject ${this.activeTasq?.wellName}?`;
        this.action.confirmText = 'Yes, reject it';
        this.$dialog.show('actionModal');
        break;
      case ('reassign'):
        this.action.type = action;
        this.action.title = `Select the user to reassign ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Reassign tasq';
        this.$dialog.show('actionModal');
        break;
      case ('wait'):
        this.action.type = action;
        this.action.title = `Please select status for ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Update';
        this.$dialog.show('actionModal');
        break;
      case ('add'):
        this.action.type = action;
        this.action.title = '';
        this.action.confirmText = 'Add tasq';
        this.action.person = accountModule.user.email;
        this.$dialog.show('actionModal');
        break;
      default:
        break;
    }
  }

  async onActionDialogClosed(response) {
    let successText = 'Success'!;

    this.startDataLoading();
    if (response.isOk) {
      switch (this.action.type) {
        case ('reassign'):
          successText = 'Successfully reassigned!';
          await tasqActionsModule.reassignTasq(this.action);
          break;
        case ('reject'):
          successText = 'Successfully Rejected!';
          await tasqActionsModule.rejectTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('wait'):
          successText = 'Successfully added to waitlist!';
          await tasqActionsModule.updateTasqStatus(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('add'):
          successText = 'Tasq added successfully!';
          await tasqActionsModule.addTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        default:
          break;
      }
    }
    this.closeEditToolbar();
    this.action = {};
    this.showActionDialog = false;
    this.sleep(2000).then(async () => {
      await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    });
    this.$router.push({name: 'Tasqs'})
    this.stopDataLoading();
    this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async changeChartTimes(val) {
    this.signalChartTime = val;
    tasqsListModule.setSignalChartTime(val);
    // tasqsListModule.signalChartTime = val;
    this.chartsLoading = true;

    const promises: any[] = [];
    promises.push(tasqSignalsModule.getSignalsForTasq(val));
    promises.push(tasqProductionDataChartModule.getProductionData(val));
    promises.push(tasqProductionDataChartModule.getWellHistory(val));
    promises.push(defermentLabelingModule.getChartLabelingData(this.signalChartTime));

    Promise.all(promises).then(() => {
      this.chartsLoading = false;
    }, (err) => {
      // error occurred

    });
  }

  async fetchTasq() {
    this.chartsLoading = true;
    this.chartTimes = this.$getConst('CHART_TIMES');
    tasqProductionDataChartModule.resetProductionDataDefermentLabeling();
    if (this.signalChartTime > 45) {
      this.signalChartTime = 14;
    }
    if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {
      await tasqsListModule.setActiveTasq(this.$route.params.id);
	  if (tasqsListModule.activeTasq == null) {
		  await tasqsListModule.getTasq({
			  PredictionID: this.$route.params.id,
		  });
	  }
    } else {
      await assetsModule.setActiveProducingTasq(this.$route.params.id);
    }
    this.getPredictionList();
    const promises: any[] = [];
    promises.push(tasqSignalsModule.getSignalsForTasq(this.signalChartTime));
    promises.push(tasqProductionDataChartModule.getProductionData(this.signalChartTime));
    // promises.push(tasqProductionDataChartModule.getWellHistory(this.signalChartTime));
    promises.push(defermentLabelingModule.getChartLabelingData(this.signalChartTime));
    if (this.activeTasq != null && (this.currentWellType != 'producing')) {
      tasqsListModule.getTasqEventHistoryList(this.activeTasq?.workflowTasqId);
      workflowModule.getWellHistory(this.activeTasq?.wellName);
      //   setpointV2Module.getSetpointRecV2ForTasq({wellName: this.activeTasq?.wellName})
	  if (this.currentWellType != 'producing') {
		//   workflowModule.getJobResponse(this.activeTasq?.id);
		  workflowModule.getJobResponse(this.activeTasq?.id);
		  await workflowModule.getJobResponseV2(this.activeTasq?.id);
		  if (workflowModule.v2ResponseData != null) {
			  
			  proceduresModule.getProceduresStepsJson({
				  jsonVersion: workflowModule.v2ResponseData.jsonVersion
			  })
		  } else {
			  proceduresModule.getProceduresStepsJson({})
		  }
	  }
    } else {
      workflowModule.getWellHistory(this.activeTasq?.wellName);
    }
    Promise.all(promises).then(() => {
      tasqsListModule.setSignalChartTime(this.signalChartTime);
      if (this.activeTasq?.engineerType === TASQ_OFF_TARGET_TYPE) {
        let jobOriginExists = false;
        for (let t = 0; t < this.chartTimes.length; t++) {
          if (this.chartTimes[t].text == 'Job Origin') {
            jobOriginExists = true;
          }
        }
        if (!jobOriginExists) {
          this.chartTimes = [{
            id: 5,
            val: this.activeTasq.dateAgoValue + 30,
            text: 'Job Origin',
            additionalClass: 'chart-time--narrow',
          }, ...this.chartTimes];
        }
      }
      this.chartsLoading = false;
    }, (err) => {
      console.log('Error:');
      console.log(err);
      // error occurred
    });
  }

   async batchReassign() {
	  if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
       await accountModule.getReassignmentList()
		  this.prepareAction('reassign')
	  }
	  
  }

  async batchWait() {
	  if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
		  this.prepareAction('wait')
	  }
	  
  }

  @Watch('$route.params.id', {
    immediate: true,
  })
  async onTasqRouteIdChange(id: string) {
    if (id) {
      await this.fetchTasq();
       this.getPredictionList();
    } else {
      tasqsListModule.setActiveTasq('');
    }
    this.multiTasqType = ''
  }
}
