import Vue from 'vue';
import Component from 'vue-class-component';
import {
  TASQ_NO_COMMS_TYPE,
  TASQ_PREDICTION_TYPE,
  TASQ_FAILURE_TYPE,
  TASQ_DOWN_TYPE,
  TASQ_OFF_TARGET_TYPE,
  TASQ_LABEL_TYPE,
  TASQ_PROLONGED_ANOMALY_TYPE,
  TASQ_PRODUCING_TYPE,
  TASQ_PM_TYPE,
  TASQ_GAUGE_TYPE,
  TASQ_REGULATORY_TYPE,
  TASQ_SITE_CHECK_TYPE,
  TASQ_SETPOINT_TYPE,
  TASQ_WELL_TEST_TYPE,
  TASQ_SCHEDULED_JOB,
  TASQ_FAILURE_PREDICTION_TYPE,
  TASQ_OFF_TARGET_RT_TYPE,
  TASQ_WORKFLOW_TYPE,
  TASQ_STATE_CHANGE_TYPE,
} from '@/lib/constants';

import { ucfirst } from '@/utils/helpers';

@Component
export default class TasqMixin extends Vue {
  dataLoading = true;

  

  created() {
    
  }

  get jobTypeOptions() {
    return [
      { key: 'Install', value: 'Install', id: 0 },
      { key: 'Install', value: 'Install Artificial Lift', id: 1 },
      { key: 'Install', value: 'Install Compressor ', id: 2 },
      { key: 'Site Check', value: 'Site Check', id: 3 },
      { key: 'Site Check', value: 'Dump Checks', id: 4 },
      { key: 'Site Check', value: 'Facility walkdown', id: 5 },
      { key: 'Site Check', value: 'Drain pots and drips', id: 6 },
      { key: 'Site Check', value: 'Drain tank bottoms', id: 7 },
      { key: 'Well Issue', value: 'Well Issue', id: 8 },
      { key: 'Label', value: 'Label', id: 9 },
      { key: 'LOTO', value: 'LOTO', id: 10 },
      { key: 'Regulatory', value: 'Regulatory', id: 11 },
      { key: 'Preventive Maintenance', value: 'Preventive Maintenance', id: 12 },
      { key: 'Preventive Maintenance', value: 'Facility Winterization', id: 13 },
      { key: 'Preventive Maintenance', value: 'Plunger Inspection', id: 14 },
      { key: 'Gauge', value: 'Gauge', id: 15 },
      { key: 'Contractor Jobs', value: 'Contractor Jobs', id: 16 },
      { key: 'Contractor Jobs', value: 'Hot Oiling', id: 17 },
    ].sort((a, b) => a.value.localeCompare(b.value));
  }
  

   getOverriddenTasqName(name) {
	   if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(name)) {
		   return "Workflow"
	   }
    switch (name) {
      case TASQ_PROLONGED_ANOMALY_TYPE:
      case TASQ_STATE_CHANGE_TYPE:
        return 'Anomaly';
      case TASQ_OFF_TARGET_RT_TYPE:
      	return 'Real-Time';
      case TASQ_WELL_TEST_TYPE:
      	return "Well test"
      case 'Off-Target RT':
        return 'REAL-TIME';
      default:
        return ucfirst(name || '');
    }
  };


  bgColorClass(predictionType) {
    // if (tasq.isWorkflowGen) return 'bg-color--pink';
  
    switch (predictionType) {
      case TASQ_PREDICTION_TYPE:
      case TASQ_FAILURE_PREDICTION_TYPE:
        return 'bg-tasqGreen';
      case TASQ_FAILURE_TYPE:
      case TASQ_OFF_TARGET_RT_TYPE:
        return 'bg-tasqRed';
      case TASQ_DOWN_TYPE:
      case TASQ_OFF_TARGET_TYPE:
        return 'bg-tasqOrange';
      case 'REAL-TIME':
      case 'Real-Time':
        return 'bg-tasqRed';
      case TASQ_LABEL_TYPE:
        return 'bg-tasqBlueLight';
      case TASQ_PRODUCING_TYPE:
      case TASQ_PM_TYPE:
      case TASQ_GAUGE_TYPE:
      case TASQ_REGULATORY_TYPE:
      case TASQ_SITE_CHECK_TYPE:
      case TASQ_NO_COMMS_TYPE:
      case TASQ_WELL_TEST_TYPE:
      case TASQ_SCHEDULED_JOB:
        return 'bg-tasqGrayDark';
    case TASQ_WORKFLOW_TYPE:
      return 'bg-tasqViolet';
      case TASQ_SETPOINT_TYPE:
        return 'bg-tasqBlue';
      default:
        return 'bg-tasqYellowDark';
    }
  };
}
